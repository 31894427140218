
import { computed, defineComponent, reactive, ref, toRefs, unref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useMessage } from '@/hooks/core/useMessage'
import UserDeptCascader from '@/components/common/UserDeptCascader.vue'

export default defineComponent({
  name: 'LoginForm',
  components: { UserDeptCascader },
  props: {
    type: Number
  },
  setup (props) {
    const Message = useMessage()
    const store = useStore()
    const router = useRouter()
    const formRef = ref<any>(null)
    const { value: loginType } = toRefs(reactive({ value: props.type }))
    const formData = reactive({
      account: '',
      name: '',
      password: '',
      dept: null,
      isLoading: false,
      type: loginType
    })

    const rules = reactive({
      account: [
        {
          required: true,
          message: '手机号/邮箱不能为空',
          trigger: 'blur'
        }
      ],
      password: [
        {
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        }
      ],
      name: [
        {
          required: true,
          message: '姓名不能为空',
          trigger: 'blur'
        }
      ],
      dept: [
        {
          required: true,
          message: '学科学段不能为空',
          trigger: 'blur'
        }
      ]
    })

    const handleSetUserDept = (value: any) => {
      if (!value) {
        return
      }
      formData.dept = value
    }

    watch(() => props.type, (newValue) => {
      loginType.value = newValue
      if (newValue === 1) {
        rules.name = [
          {
            required: true,
            message: '姓名不能为空',
            trigger: 'blur'
          }
        ]
        rules.dept = [
          {
            required: true,
            message: '学科学段不能为空',
            trigger: 'blur'
          }
        ]
      } else {
        rules.name = []
        rules.dept = []
      }
    }, { immediate: true })

    const loginBtnText = computed(() => {
      const { isLoading } = formData
      const txt = loginType.value === 0 ? '登录' : '注册'
      return isLoading ? `${txt}中...` : txt
    })

    const handleSubmitClick = () => {
      const form = unref(formRef)
      if (!form) {
        return
      }
      form.validate(async (valid: boolean) => {
        if (!valid) {
          return
        }
        formData.isLoading = true
        try {
          const loginParams = {
            account: formData.account,
            password: formData.password,
            name: formData.name,
            dept: formData.dept
          }
          const dispatchAction = formData.type === 0 ? 'user/login' : 'user/register'
          const messageType = formData.type === 0 ? '登录' : '注册'
          const submitResult = await store.dispatch(dispatchAction, loginParams)
          if (submitResult) {
            Message({
              type: 'success',
              message: `${messageType}成功`,
              duration: 1500,
              onClose: () => {
                router.push('/')
              }
            })
          }
        } catch (e) {
          Message.error(e.message || '操作失败')
        } finally {
          formData.isLoading = false
        }
      })
    }

    return {
      formRef,
      formData,
      rules,
      loginBtnText,
      handleSetUserDept,
      handleSubmitClick
    }
  }
})

