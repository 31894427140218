<template>
  <el-cascader v-model="localValue"
               :options="options"
               :show-all-levels="true"
               clearable
               filterable
               placeholder="请选择学科学段"
               style="width: 100%"
  />
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { getDeptDict } from '@/api/common'

// eslint-disable-next-line no-undef
const emit = defineEmits(['change'])

const localValue = ref('')
const options = ref([{}])

const formatDeptData = (data) => {
  return data.map((item) => ({
    label: item.name,
    value: item.id,
    children: item.children ? formatDeptData(item.children) : []
  }))
}

onMounted(async () => {
  try {
    const response = await getDeptDict()
    if (response.code === 2000 && response.result) {
      options.value = formatDeptData(response.result)
    }
  } catch (error) {
    console.error('Failed to load department data:', error)
  }
})

// 监控 localValue 的变化
watch(localValue, (arr) => {
  if (arr.length > 0) {
    const lastElement = arr[arr.length - 1]
    emit('change', lastElement)
  } else {
    console.log('数组是空的')
  }
})
</script>
